.help-p {
  text-align: center;
  color: #ffffff;

  a {
    color: #4070f4;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
