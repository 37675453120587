.container-list {
  display: flex;

  .list-items {
    text-align: left;
    list-style-position: inside;
    list-style-type: none;

    li {
      padding-left: 2rem;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .list-items.center {
    text-align: center;
  }
  .list-items.bullets li {
    background-image: url("../../../img/bullet.svg");
  }
  .list-items.bags li {
    background-image: url("../../../img/walkie-talkie.svg");
  }
}
.container-list.mobile {
  justify-content: center;

  li {
    padding-left: 1rem;
  }
}
.container-list.right {
  justify-content: flex-end;
}

/* Media Query For different screens */
@media (max-width: 749px) {
  .container-list .list-items.bullets li {
    padding: 0;
    background-image: none;
  }
  .container-list .list-items {
    list-style-image: url("../../../img/bullet.svg");
    list-style-position: inside;
  }
}
@media (max-width: 499px) {
  .container-list .list-items {
    font-size: 11px;
  }
}
@media (min-width: 500px) and (max-width: 749px) {
  .container-list .list-items {
    font-size: 12px;
  }
}
@media (min-width: 750px) and (max-width: 810px) {
  .container-list .list-items {
    font-size: 9px;

    li {
      padding-left: 1rem;
    }
  }
}
@media (min-width: 811px) and (max-width: 944px) {
  .container-list .list-items {
    font-size: 10px;

    li {
      padding-left: 1rem;
    }
  }
}
@media (min-width: 945px) and (max-width: 1179px) {
  .container-list .list-items {
    font-size: 12px;

    li {
      padding-left: 1rem;
    }
  }
}
@media (min-width: 1180px) and (max-width: 1400px) {
  .container-list .list-items {
    font-size: 15px;

    li {
      padding-left: 1.5rem;
    }
  }
}
@media (min-width: 900px) and (orientation: portrait) {
  .container-list .list-items {
    font-size: 11px;

    li {
      padding-left: 1.5rem;
    }
  }
}
