.article-body {
  display: flex;
  height: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px;
  box-sizing: border-box;
}
#visa-desc {
  .input-row {
    width: 100%;

    label {
      padding-left: 10px;
      font-size: 16px;
      font-weight: 500;
      font-family: sans-serif;
    }
    .input-box {
      position: relative;

      input {
        display: block;
        width: 100%;
        height: 30px;
        padding-left: 10px;
        font-size: 17px;
        border-radius: 20px;
        color: white;
        background: rgba(0, 0, 0, 0.656);
        border: none;
        transition: background 0.5s;
        box-sizing: border-box;

        &:focus {
          outline-width: 0;
          background: rgba(16, 17, 17, 0.742);
          transition: background 0.5s;
        }
      }
      .copy-toggle-icon {
        position: absolute;
        top: 55%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;

        .copy {
          font-size: 24px;
          line-height: 1;
          color: #ffffff;
          transition: color 0.3s ease-in-out;
          margin-bottom: 20px;

          &:hover {
            color: #d2d2d2;
          }
        }
        .checkmark {
          font-size: 30px;
          line-height: 1;
          color: #0dbd00;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1100px) {
  #visa-desc .input-box input {
    width: 284px;
    height: 28px;
    font-size: 15px;
  }
}
@media (min-width: 1100px) and (max-width: 1200px) {
  #visa-desc .input-box input {
    width: 284px;
    height: 28px;
    font-size: 15px;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  #visa-desc .input-box input {
    width: 328px;
    height: 29px;
    font-size: 16px;
  }
}
@media (min-width: 720px) and (max-height: 540px) {
  #visa-desc {
    label {
      font-size: 12px;
    }
    .input-box {
      input {
        width: 190px;
        height: 17px;
        font-size: 10px;
      }
      .copy-toggle-icon {
        right: 5px;

        .copy {
          font-size: 15px;
        }
      }
    }
  }
}
