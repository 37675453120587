.items-main {
  display: flex;
  justify-content: center;

  .gradient-cards {
    padding: 30px 0;
    overflow: hidden;

    .card {
      max-width: 1000px;
      border: 0;
      width: 100%;
      margin-inline: auto;
      transition: all 2s ease;
      opacity: 0;

      .container-card {
        display: grid;
        grid-template-columns: 2fr auto;
        column-gap: 1em;
        background: linear-gradient(71deg, #080509, #1a171c, #080509);
        background-clip: padding-box;
        border-radius: 45px;
        padding: 40px;

        .column1 {
          text-align: center;
        }
        .item-buttons {
          grid-row-start: 2;
          grid-column-start: 1;
          grid-column-end: 3;
          margin-top: 15px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
    .slide-in {
      opacity: 1 !important;
    }
  }
  .gradient-cards:nth-child(odd) .card {
    transform: translateX(-100%);
  }
  .gradient-cards:nth-child(even) .card {
    transform: translateX(100%);
  }
  .gradient-cards:nth-child(odd) .slide-in {
    transform: translateX(0) !important;
    float: right;
  }
  .gradient-cards:nth-child(even) .slide-in {
    transform: translateX(0) !important;
    float: left;
  }
}

/* Media Query For different screens */
@media (max-width: 900px) {
  .items-main .gradient-cards .card .container-card {
    display: block;
    text-align: center;
  }
  .items-main .gradient-cards .card .container-card .column2 {
    padding-top: 10px;
  }
}
@media (max-width: 540px) {
  .items-main .gradient-cards .card .container-card {
    padding: 30px;
  }
}
@media (min-width: 760px) and (max-width: 1024px) and (orientation: portrait) {
  .items-main .gradient-cards .card .container-card .column1 {
    display: flex;
    justify-content: center;

    .item-buttons {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 10px;
    }
  }
}
