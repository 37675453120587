.lang-menu {
  min-width: 44px;
  width: 120px;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;

  .language-select__control {
    font-size: 14px;
    height: 40px;
  }
  .language-select__indicator {
    padding: 3px;
  }
  .language-select__option {
    color: black;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
  }
  .language-select__img {
    width: 20px;
    height: 15px;
    margin-right: 8px;
  }
}

@media (max-width: 750px) {
  .lang-menu {
    width: 70px;

    .language-select__control {
      font-size: 14px;
      height: 20px;
    }
    .language-select__indicator {
      padding: 0;
    }
    .language-select__option {
      color: black;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
    }
    .language-select__img {
      width: 20px;
      height: 15px;
      margin-right: 8px;
    }
    .language-select__value-container {
      padding: 0;
    }
  }
}
@media (max-width: 374px) {
  .lang-menu {
    width: auto;
  }
}
