.donators {
  background: radial-gradient(#0093a9 8%, transparent 8%), white;
  background-size: 25px 25px;
  height: 100vh;
  max-height: 1200px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  & > .title-container {
    flex-shrink: 0;
  }
  .donators-main {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin: 20px 0;
    max-height: calc(100vh - 40px);
    overflow: hidden;

    .donators-background {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 10px;

      .icons-back {
        height: 100%;
        max-height: 1000px;
        width: 100%;
        background-color: #1e293b;
        text-align: center;
        border-radius: 10px;
        border: 2px solid rgba(255, 255, 255, 0.1);
        position: relative;
        overflow: hidden;

        /* Overlay */
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          z-index: 5;
        }
        .donators-card {
          height: 100%;
          width: 100%;
          border-radius: 10px;
          border: 2px solid rgba(255, 255, 255, 0.1);
          display: flex;
          align-items: center;
          position: relative;
          z-index: 5;
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .donators .donators-main .donators-background {
    display: grid;
    grid-template-columns: fit-content(30%) 1fr;
  }
}
