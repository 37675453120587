@import url("https://fonts.googleapis.com/css?family=Dosis:300,400,500");

@-moz-keyframes rocket-movement {
  100% {
    -moz-transform: translate(1200px, -600px);
  }
}
@-webkit-keyframes rocket-movement {
  100% {
    -webkit-transform: translate(1200px, -600px);
  }
}
@keyframes rocket-movement {
  100% {
    transform: translate(1200px, -600px);
  }
}
@-moz-keyframes spin-earth {
  100% {
    -moz-transform: rotate(-360deg);
    transition: transform 20s;
  }
}
@-webkit-keyframes spin-earth {
  100% {
    -webkit-transform: rotate(-360deg);
    transition: transform 20s;
  }
}
@keyframes spin-earth {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    transition: transform 20s;
  }
}

@-moz-keyframes move-astronaut {
  100% {
    -moz-transform: translate(-160px, -160px);
  }
}
@-webkit-keyframes move-astronaut {
  100% {
    -webkit-transform: translate(-160px, -160px);
  }
}
@keyframes move-astronaut {
  100% {
    -webkit-transform: translate(-160px, -160px);
    transform: translate(-160px, -160px);
  }
}
@-moz-keyframes rotate-astronaut {
  100% {
    -moz-transform: rotate(-720deg);
  }
}
@-webkit-keyframes rotate-astronaut {
  100% {
    -webkit-transform: rotate(-720deg);
  }
}
@keyframes rotate-astronaut {
  100% {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

@-moz-keyframes glow-star {
  40% {
    -moz-opacity: 0.3;
  }
  90%,
  100% {
    -moz-opacity: 1;
    -moz-transform: scale(1.2);
  }
}
@-webkit-keyframes glow-star {
  40% {
    -webkit-opacity: 0.3;
  }
  90%,
  100% {
    -webkit-opacity: 1;
    -webkit-transform: scale(1.2);
  }
}
@keyframes glow-star {
  40% {
    -webkit-opacity: 0.3;
    opacity: 0.3;
  }
  90%,
  100% {
    -webkit-opacity: 1;
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    border-radius: 999999px;
  }
}

.error404 {
  position: relative;
  margin-top: 0;
  width: 100%;
  height: 100vh;
  font-family: "Dosis", sans-serif;
  font-weight: 300;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
  background: url(http://salehriaz.com/404Page/img/bg_purple.png);
  background-repeat: repeat-x;
  background-size: cover;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  .stars {
    background: url(http://salehriaz.com/404Page/img/overlay_stars.svg);
    background-repeat: repeat;
    background-size: contain;
    width: 100%;

    .central-body {
      padding: 17% 5% 10% 5%;
      text-align: center;

      .image-404 {
        position: relative;
        z-index: 100;
        pointer-events: none;
      }
      .btn-go-home {
        position: relative;
        z-index: 200;
        margin: 15px auto;
        width: 100px;
        padding: 10px 15px;
        border: 1px solid #ffcb39;
        border-radius: 100px;
        font-weight: 400;
        display: block;
        color: white;
        text-align: center;
        text-decoration: none;
        letter-spacing: 2px;
        font-size: 11px;

        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;

        &:hover {
          background-color: #ffcb39;
          color: #fff;
          transform: scale(1.05);
          box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
        }
      }
    }
    .objects {
      img {
        z-index: 90;
        pointer-events: none;
      }
      .object_rocket {
        z-index: 95;
        position: absolute;
        transform: translateX(-50px);
        top: 75%;
        pointer-events: none;
        animation: rocket-movement 200s linear infinite both running;
      }
      .object_earth {
        position: absolute;
        top: 20%;
        left: 15%;
        z-index: 90;
        animation: spin-earth 100s infinite linear both;
      }
      .object_moon {
        position: absolute;
        top: 12%;
        left: 25%;
      }
      .box_astronaut {
        z-index: 110 !important;
        position: absolute;
        top: 60%;
        right: 20%;
        will-change: transform;
        animation: move-astronaut 50s infinite linear both alternate;

        .object_astronaut {
          animation: rotate-astronaut 200s infinite linear both alternate;
        }
      }
    }
    .glowing_stars .star {
      position: absolute;
      border-radius: 100%;
      background-color: #fff;
      width: 3px;
      height: 3px;
      opacity: 0.3;
      will-change: opacity;
    }
    .glowing_stars .star:nth-child(1) {
      top: 80%;
      left: 25%;
      animation: glow-star 2s infinite ease-in-out alternate 1s;
    }
    .glowing_stars .star:nth-child(2) {
      top: 20%;
      left: 40%;
      animation: glow-star 2s infinite ease-in-out alternate 3s;
    }
    .glowing_stars .star:nth-child(3) {
      top: 25%;
      left: 25%;
      animation: glow-star 2s infinite ease-in-out alternate 5s;
    }
    .glowing_stars .star:nth-child(4) {
      top: 75%;
      left: 80%;
      animation: glow-star 2s infinite ease-in-out alternate 7s;
    }
    .glowing_stars .star:nth-child(5) {
      top: 90%;
      left: 50%;
      animation: glow-star 2s infinite ease-in-out alternate 9s;
    }
  }
}

/* Media Query For different screens */
@media (orientation: portrait) and (min-width: 320px) and (max-width: 344px) {
  .error404 .stars .central-body {
    padding-top: 25%;
    margin: 58% auto;
  }
  .error404 .stars .objects .box_astronaut {
    top: 70%;
    right: 2%;
  }
}
@media (orientation: portrait) and (min-width: 345px) and (max-width: 389px) {
  .error404 .stars .objects .box_astronaut {
    top: 20%;
    right: 2%;
  }
}
@media (orientation: portrait) and (min-width: 390px) and (max-width: 600px) {
  .error404 .stars .objects .box_astronaut {
    top: 70%;
    right: 2%;
  }
}
@media (orientation: portrait) and (min-width: 345px) and (max-width: 600px) {
  .error404 .stars .central-body {
    padding-top: 190px;
    margin: 50% auto;
  }
}
@media (orientation: portrait) and (min-width: 700px) {
  .error404 .stars .central-body {
    padding: 0 0 0 0;
    margin: 50% auto;
  }
  .error404 .stars .objects .box_astronaut {
    top: 70%;
    right: 2%;
  }
  .error404 .stars .central-body .btn-go-home {
    width: 250px;
    font-size: 30px;
    font-weight: 450;
    margin: 55px auto;
  }
  .error404 .stars .central-body .image-404 {
    width: 400px;
  }
}
@media (orientation: landscape) and (min-width: 740px) {
  .error404 .stars .central-body {
    padding: 0 0 0 0;
    margin: 26% auto;
  }
  .error404 .stars .central-body .btn-go-home {
    width: 250px;
    font-size: 30px;
    font-weight: 450;
    margin: 55px auto;
  }
}
@media (orientation: landscape) and (min-width: 1280px) and (max-height: 800px) {
  .error404 .stars .central-body {
    padding: 0 0 0 0;
    margin: 18% auto;
  }
}
@media (orientation: landscape) and (min-width: 1600px) and (max-height: 999px) {
  .error404 .stars .central-body {
    padding: 0 0 0 0;
    margin: 18% auto;
  }
}
@media (orientation: landscape) and (min-width: 1280px) and (min-height: 800px) {
  .error404 .stars .central-body .image-404 {
    width: 400px;
  }
}
@media (max-height: 720px) and (min-width: 540px) and (orientation: portrait) {
  .error404 .stars .central-body {
    padding: 0 0 0 0;
    margin: 50% auto;
  }
  .error404 .stars .objects .box_astronaut {
    top: 70%;
    right: 2%;
  }
}
@media (max-height: 720px) and (min-width: 540px) and (orientation: landscape) {
  .error404 .stars .central-body {
    padding: 0 0 0 0;
    margin: 20% auto;
  }
  .error404 .stars .objects .box_astronaut {
    top: 70%;
    right: 2%;
  }
}
