.arrow-container {
  z-index: 12;
  height: 65px;

  .moving-arrow {
    display: flex;
    justify-content: center;
    align-items: center;

    a .arrow-down {
      display: inline-block;
      width: 10px;
      height: 40px;
      background: white;
      border-radius: 5px;
      margin: 6px;
      animation: move 1.1s infinite ease-in-out;
    }
    a .arrow-down:nth-of-type(1) {
      transform: rotate(-45deg);
    }
    a .arrow-down:nth-of-type(2) {
      transform: rotate(45deg);
    }
  }
}

@keyframes move {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 30px;
  }
  100% {
    margin-top: 0;
  }
}

@media (max-width: 499px) {
  .arrow-container .moving-arrow a .arrow-down {
    width: 4px;
    height: 25px;
  }
}
@media (min-width: 720px) and (max-width: 1300px) and (orientation: landscape) {
  .arrow-container .moving-arrow a .arrow-down {
    width: 6px;
    height: 30px;
  }
}
