.input-data {
  height: 40px;
  margin: 10px 20px;
  position: relative;

  input {
    height: 100%;
    text-align: center;
    color: #c4c3ca;
    background-color: #1f2029;
    border: none;
    border-radius: 4px;
    font-size: 17px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    outline: none;

    &:focus ~ label,
    &:valid ~ label {
      transform: translate(-77px, -20px);
      font-size: 14px;
      color: #ffffff;
    }
    &:focus ~ .underline:before,
    &:valid ~ .underline:before {
      transform: scale(1);
    }
  }
  label {
    position: absolute;
    left: 177px;
    pointer-events: none;
    bottom: 10px;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  .underline {
    bottom: 0;
    height: 2px;
    width: 100%;

    &:before {
      position: absolute;
      left: 100px;
      top: 40px;
      content: "";
      height: 2px;
      width: 221px;
      background: #ffffff;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.3s ease;
    }
  }
  .icon {
    position: absolute;
    top: 1px;
    left: 110px;
    height: 48px;
    font-size: 24px;
    line-height: 48px;
    text-align: left;
    color: #ffeba7;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
}

/* Media Query For different screens */
@media (min-width: 320px) and (max-width: 344px) {
  .input-data {
    .underline::before {
      left: 15px;
    }
    .icon {
      top: 1px;
      left: 20px;
    }
    label {
      left: 95px;
    }
  }
}
@media (min-width: 345px) and (max-width: 389px) {
  .input-data {
    .underline::before {
      left: 25px;
    }
    .icon {
      top: 1px;
      left: 30px;
    }
    label {
      left: 105px;
    }
  }
}
@media (min-width: 390px) and (max-width: 399px) {
  .input-data {
    .underline::before {
      left: 40px;
    }
    .icon {
      top: 1px;
      left: 45px;
    }
    label {
      left: 120px;
    }
  }
}
@media (min-width: 400px) and (max-width: 479px) {
  .input-data {
    .underline::before {
      left: 50px;
    }
    .icon {
      top: 1px;
      left: 55px;
    }
    label {
      left: 130px;
    }
  }
}
@media (min-width: 750px) and (max-width: 1200px) and (orientation: portrait) {
  .input-data {
    height: 50px;
    margin: 14px 25px;
    position: relative;

    input {
      font-size: 20px;

      &:focus ~ label,
      &:valid ~ label {
        font-size: 14px;
      }
    }
    .underline:before {
      left: 123px;
      top: 49px;
      width: 265px;
    }
    label {
      left: 202px;
      bottom: 21px;
    }
    .icon {
      left: 130px;
      top: 8px;
      top: 8px;
    }
  }
}
@media (min-width: 1300px) and (min-height: 912px) {
  .input-data {
    height: 50px;
    margin: 14px 25px;
    position: relative;

    input {
      font-size: 20px;

      &:focus ~ label,
      &:valid ~ label {
        font-size: 14px;
      }
    }
    .underline:before {
      left: 123px;
      top: 49px;
      width: 265px;
    }
    label {
      left: 202px;
      bottom: 21px;
    }
    .icon {
      left: 130px;
      top: 8px;
      top: 8px;
    }
  }
}
@media (min-width: 720px) and (max-height: 540px) {
  .input-data {
    height: 34px;
    margin: 8px 17px;
    position: relative;

    input {
      font-size: 14px;

      &:focus ~ label,
      &:valid ~ label {
        font-size: 11px;
      }
    }
    .underline:before {
      left: 69px;
      top: 34px;
      width: 181px;
    }
    label {
      left: 147px;
    }
    .icon {
      left: 73px;
      top: -2px;
    }
  }
}
@media (min-width: 1024px) and (max-height: 600px) {
  .input-data {
    height: 36px;
    margin: 9px 18px;
    position: relative;

    input {
      font-size: 15px;

      &:focus ~ label,
      &:valid ~ label {
        font-size: 12px;
      }
    }
    .underline:before {
      left: 64px;
      top: 37px;
      width: 197px;
    }
    label {
      left: 140px;
    }
    .icon {
      left: 68px;
    }
  }
}
