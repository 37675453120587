.description {
  display: flex;
  justify-content: center;

  .form-control {
    width: 570px;
    height: 120px;
    padding: 1em;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: #838788;

    &.thick {
      padding: 0.5rem;
    }

    &:focus {
      background-color: #f2f6f8;
      outline: 0;
      border-color: #bd8200;
      box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.11);
    }
    &:after {
      border-right-color: #f0a500;
    }
  }
}
