.submit-but {
  width: 100%;
  padding: 15px 0;
  border: none;
  background-color: #4070f4;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #0e4bf1;
  }
}

@media (max-height: 665px) and (orientation: landscape) {
  .submit-but {
    height: 44px;
    font-size: 14px;
  }
}
