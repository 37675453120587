.text-description {
  width: 100%;
  height: 100%;
  max-width: 1800px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  position: relative;
  z-index: 10;
  color: #fff;
}

/* Media Query For different screens */
@media (max-width: 749px) {
  .text-description {
    padding: 0 10px;
  }
}
@media (min-width: 750px) and (max-width: 1900px) {
  .text-description {
    padding: 0 50px;
  }
}
