/* Landscape version question */
.questions {
  display: flex;
  align-items: center;
  color: #ffffff;

  .question {
    font-size: clamp(0.6rem, 1vw, 1.25rem);
  }
  .tooltip {
    position: relative;

    .question-icon {
      font-size: 20px;
      margin: 0 5px;
      z-index: 120;
      cursor: pointer;
    }
    .tooltiptext {
      visibility: hidden;
      text-align: center;
      position: absolute;
      z-index: 3;
      top: 10px;
      left: -45px;
      background: white;
      margin-top: 22px;
      padding: 10px;
      color: black;
      line-height: 1.5;
      width: 350px;
      margin-left: -125px;
      border-radius: 10px;
      font-family: "Ubuntu", sans-serif;
    }
    .tooltiptext::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent white transparent;
    }
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
    margin-top: 18px;
    transition: all 500ms ease;
  }
}
.left-q {
  justify-content: flex-end;
}
.right-q {
  justify-content: flex-start;
}

/* Portrait version question */
.mob-tooltip {
  position: absolute;
  bottom: 20px;
  left: 57px;
  width: 100%;
  display: flex;
  color: rgb(189, 36, 36);

  .question-icon {
    font-size: 20px;
    color: rgb(189, 36, 36);
  }
  .tooltiptext {
    text-align: center;
    visibility: hidden;
    background-color: rgb(189, 36, 36);
    color: #fff;
    margin-top: 22px;
    padding: 10px;
    line-height: 1.5;
    width: 286px;
    margin-left: -125px;
    border-radius: 10px;
    font-family: "Ubuntu", sans-serif;
    position: absolute;
    z-index: 3;
    bottom: 150%;
    left: 110px;
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(189, 36, 36) transparent transparent transparent;
  }
  &:hover .tooltiptext {
    visibility: visible;
    z-index: 100;
  }
}

@media screen and (min-width: 320px) and (max-width: 344px) {
  .mob-tooltip {
    left: 32px;
  }
}
