.item-image {
  width: 380px;
  border-radius: 12px;
}

@media screen and (max-width: 900px) {
  .item-image {
    width: 100%;
    margin-bottom: 0;
  }
}
