.form-element {
  width: 200px;
  height: 200px;
  margin: auto;
  position: relative;
  box-shadow: 0px 0px 20px 5px rgba(100, 100, 100, 0.1);

  input {
    display: none;
  }
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
  .upload-content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
    .upload-image {
      height: 100px;
      font-size: 50px;
      line-height: 100px;
    }
    h2 {
      font-size: 25px;
      color: #ffe100;
    }
    & > * {
      transform: translateY(25px);
      transition: transform 0.6s;
    }
    &:hover > * {
      transform: translateY(0px);
    }
  }
}

@media (min-width: 750px) and (max-width: 1200px) and (orientation: portrait) {
  .form-element {
    width: 300px;
    height: 300px;

    img {
      width: 300px;
      height: 300px;
    }
  }
}
@media (min-width: 1300px) and (min-height: 912px) {
  .form-element {
    width: 300px;
    height: 300px;

    img {
      width: 300px;
      height: 300px;
    }
  }
}
@media (min-width: 720px) and (max-height: 540px) {
  .form-element {
    width: 160px;
    height: 160px;

    img {
      width: 160px;
      height: 160px;
    }
  }
}
@media (min-width: 1024px) and (max-height: 600px) {
  .form-element {
    width: 170px;
    height: 170px;

    img {
      width: 170px;
      height: 170px;
    }
  }
}
