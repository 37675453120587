.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  label {
    color: #000000;
    font: 800 0.85rem "Montserrat";
    letter-spacing: 0.08rem;
    text-transform: uppercase;
  }
  .input-icon {
    position: relative;

    .form-style {
      padding: 13px 20px;
      padding-left: 55px;
      font-weight: 500;
      border-radius: 4px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      outline: none;
      color: #c4c3ca;
      background-color: #1f2029;
      border: none;
      -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
      box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.2);

      &:focus,
      &:active {
        border: none;
        outline: none;
        box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.2);
      }
    }
    .icon {
      position: absolute;
      top: 4px;
      left: 10px;
      height: 48px;
      font-size: 24px;
      line-height: 48px;
      text-align: left;
      color: #ffeba7;
      -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
    }
  }
}
.form-group input:-ms-input-placeholder {
  color: #c4c3ca;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input::-moz-placeholder {
  color: #c4c3ca;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:-moz-placeholder {
  color: #c4c3ca;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input::-webkit-input-placeholder {
  color: #c4c3ca;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus:-ms-input-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus::-moz-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus:-moz-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

/* Media Query For different screens */
@media (min-width: 320px) and (max-width: 344px) {
  .form-group {
    padding-left: 0;
    width: 290px;

    .input-icon .form-style {
      padding: 13px 2px;
      padding-left: 45px;
      font-size: 11px;
    }
  }
}
@media (min-width: 345px) and (max-width: 389px) {
  .form-group {
    padding-left: 0;
    width: 310px;

    .input-icon .form-style {
      padding: 13px 2px;
      padding-left: 45px;
      font-size: 12px;
    }
  }
}
@media (min-width: 390px) and (max-width: 399px) {
  .form-group {
    padding-left: 0;
    width: 340px;

    .input-icon .form-style {
      padding: 13px 2px;
      padding-left: 55px;
      font-size: 12px;
    }
  }
}
@media (min-width: 400px) and (max-width: 479px) {
  .form-group {
    padding-left: 0;
    width: 360px;
  }
}
@media (min-width: 750px) and (max-width: 1200px) and (orientation: portrait) {
  .form-group {
    label {
      font: 800 1.55rem "Montserrat";
    }
    .input-icon .form-style {
      padding: 22px 13px;
      padding-left: 55px;
      font-size: 20px;
    }
    .input-icon .icon {
      top: 14px;
      font-size: 34px;
    }
  }
}
@media (min-width: 1300px) and (min-height: 912px) {
  .form-group {
    label {
      font: 800 1.55rem "Montserrat";
    }
    .input-icon .form-style {
      padding: 22px 13px;
      padding-left: 55px;
      font-size: 20px;
    }
    .input-icon .icon {
      top: 14px;
      font-size: 34px;
    }
  }
}
@media (min-width: 720px) and (max-height: 540px) {
  .form-group {
    padding: 8px;

    .input-icon .form-style {
      padding: 8px 9px;
      padding-left: 36px;
    }
  }
}
@media (min-width: 1024px) and (max-height: 600px) {
  .form-group .input-icon .form-style {
    padding: 9px 10px;
    padding-left: 50px;
  }
}
