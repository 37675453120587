@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i&display=swap");

.payment-container {
  height: 90%;
  max-height: 1000px;

  .pc-version {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr fit-content(40%) 1fr;
    grid-template-rows: auto auto auto;
    gap: clamp(10px, 2vw, 20px);
    margin-inline: auto;

    .payment-methods {
      transform-style: preserve-3d;
      transition: all 0.8s ease;
    }
    .others {
      transform: rotateY(180deg);
    }
    article {
      --img-scale: 1.001;
      --title-color: rgb(255, 255, 255);

      position: relative;
      border-radius: 16px;
      box-shadow: none;
      background: #fff;
      transform-origin: center;
      transition: all 0.4s ease-in-out;
      height: 100%;
      width: 100%;
      margin: auto;

      .front {
        backface-visibility: hidden;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .back {
        backface-visibility: hidden;
        overflow: hidden;
        transform: rotateY(180deg);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .index-100 {
        z-index: 100;
      }

      /* using the has() relational pseudo selector to update our custom properties */
      article:has(:hover, :focus) {
        --img-scale: 1.1;
        --title-color: #81ecfa;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
          rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      }
    }
    .yellow {
      background-color: rgb(229, 229, 21);
    }
    .blue {
      background-color: #2f80ed;
    }
    .change-method-container {
      grid-column-start: 1;
      grid-column-end: 4;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
