#delete-user {
  --border: 5px; /* the border width */
  --slant: 0.7em; /* control the slanted corners */
  --color: #c51a00; /* the color */

  position: absolute;
  top: 20px;
  right: 20px;
  width: 94px;
  height: 50px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: medium;
  color: #fff;
  background: linear-gradient(to bottom left, var(--color) 50%, #0000 50.1%) top
      right,
    linear-gradient(to top right, var(--color) 50%, #0000 50.1%) bottom left;
  background-size: calc(var(--slant) + 1.3 * var(--border))
    calc(var(--slant) + 1.3 * var(--border));
  background-repeat: no-repeat;
  box-shadow: 0 0 0 200px inset var(--s, #0000),
    0 0 0 var(--border) inset var(--color);
  clip-path: polygon(
    0 0,
    calc(100% - var(--slant)) 0,
    100% var(--slant),
    100% 100%,
    var(--slant) 100%,
    0 calc(100% - var(--slant))
  );
  transition: color var(--t, 0.3s), background-size 0.3s;
}
#delete-user:focus-visible {
  outline-offset: calc(-1 * var(--border));
  outline: var(--border) solid #000c;
  clip-path: none;
  background-size: 0 0;
}
#delete-user:hover,
#delete-user:active {
  background-size: 100% 100%;
  color: #fff;
  --t: 0.2s 0.1s;
}
#delete-user:active {
  --s: #0005;
  transition: none;
}
#delete-user.item {
  position: relative;
  top: 0;
  right: 0;
  color: #c51a00;

  &:hover,
  &:active {
    background-size: 100% 100%;
    color: #fff;
    --t: 0.2s 0.1s;
  }
}

/* Media Query For different screens */
@media (min-width: 320px) and (max-width: 344px) {
  #delete-user.profile {
    --border: 3px;

    right: 4px;
    width: 58px;
    height: 31px;
    font-size: 10px;
  }
}
@media (min-width: 345px) and (max-width: 430px) {
  #delete-user.profile {
    --border: 4px;

    width: 62px;
    height: 33px;
    font-size: 10px;
  }
}
@media (min-width: 345px) and (max-width: 389px) {
  #delete-user.profile {
    right: 7px;
  }
}
@media (min-width: 370px) and (max-width: 389px) {
  #delete-user.profile {
    right: 6px;
  }
}
@media (min-width: 390px) and (max-width: 399px) {
  #delete-user.profile {
    right: 11px;
  }
}
@media (min-width: 750px) and (max-width: 1200px) and (orientation: portrait) {
  #delete-user.profile {
    width: 98px;
    height: 44px;
  }
}
@media (min-width: 1300px) and (min-height: 912px) {
  #delete-user.profile {
    width: 98px;
    height: 44px;
  }
}
@media (min-width: 720px) and (max-height: 540px) {
  #delete-user.profile {
    top: 14px;
    right: 25px;
    width: 68px;
    height: 34px;
    font-size: 11px;
  }
}
@media (min-width: 1024px) and (max-height: 600px) {
  #delete-user.profile {
    width: 80px;
    height: 36px;
    font-size: 13px;
  }
}
