.metamask {
  padding: 28px;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 0;

    .header-icon {
      width: 32px; /* Adjust as needed */
      height: 32px;
    }

    .header-text {
      font-size: 32px; /* Adjust font size */
      font-weight: bold;
      color: #ffffff; /* Color of your choice */
    }
  }
  label {
    font-weight: 700;
    font-size: 15px;
    color: #ffffff;
    margin-top: 8px;
    padding: 5px;
    display: block;
  }
  .address-container {
    color: #ffffff;
    font-weight: 700;
    font-size: 15px;
    margin: 20px 0;

    .address {
      font-weight: 500;
      font-size: 14px;
      margin-top: 8px;
      display: block;
    }
    a {
      color: #ffbb00;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .network-select__control {
    font-size: 14px;
    height: 40px;
  }
  .network-select__indicator {
    padding: 3px;
  }
  .network-select__option {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .network-select__img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .form-control {
    width: 100%;
    height: 38px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 16px;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #80bdff;
      outline: none;
    }
  }
  .btn-primary {
    background-color: #eaac00;
    color: #ffffff;
    border: none;
    width: 100%;
    padding: 18px;
    margin-top: 35px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #ff9d00;
    }
  }
  .alert {
    padding: 10px;
    border-radius: 4px;
    margin-top: 16px;
  }
}

@media (min-width: 1024px) and (max-width: 1180px) {
  .metamask {
    .address-container {
      margin: 6px 0;

      .address {
        font-size: 11px;
      }
    }
    .btn-primary {
      margin-top: 18px;
    }
  }
}
@media (min-width: 720px) and (max-height: 540px) {
  .metamask {
    padding: 17px;

    .header {
      .header-icon {
        width: 26px;
        height: 26px;
      }
      .header-text {
        font-size: 25px;
      }
    }
    .address-container {
      margin: 0;
      font-size: 12px;

      .address {
        font-size: 8px;
      }
    }
    label {
      font-size: 13px;
      margin-top: 0;
    }
    .network-select__control {
      font-size: 12px;
      height: 28px;
      min-height: 28px;
    }

    .network-select__option {
      font-size: 12px;
    }

    .network-select__img {
      width: 16px;
      height: 16px;
    }
    .form-control {
      height: 28px;
      font-size: 12px;
      margin-bottom: 0;
    }
    .btn-primary {
      margin-top: 18px;
      padding: 14px;
      font-size: 16px;
    }
  }
}
