.slider {
  height: 94vh;
  width: 100%;
  position: relative;

  .slider-text-image {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Overlay */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      z-index: 5;
    }

    .slider-image {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }
}
