.portrait-donate-slider {
  background: #fafafa;
  border: 1px solid #eaeaea;
  padding: 4px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;

  button {
    width: 80px;
    height: 80px;
    color: gray;
    background: #efefef;
    border-radius: 100%;
    border: 1px solid gray;
    transition: 1s;
    outline: none;
    -webkit-box-shadow: -2px 2px 10px 0px rgba(92, 92, 92, 1);
    -moz-box-shadow: -2px 2px 10px 0px rgba(92, 92, 92, 1);
    box-shadow: -2px 2px 10px 0px rgba(92, 92, 92, 1);

    img {
      width: 40px;
      height: 40px;
    }
  }

  button:hover {
    background: #dadada;
    transition: 1s;
  }

  button:focus {
    color: black;
    background: #dadada;
    border: 1px solid black;
    -webkit-box-shadow: inset -2px 2px 10px 0px rgba(92, 92, 92, 1);
    -moz-box-shadow: inset -2px 2px 10px 0px rgba(92, 92, 92, 1);
    box-shadow: inset -2px 2px 10px 0px rgba(92, 92, 92, 1);
  }
}

@media screen and (min-width: 320px) and (max-width: 344px) {
  .portrait-donate-slider {
    margin: 17px 0;

    button {
      width: 60px;
      height: 60px;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}
