.donate-card {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  background-color: #5b5b5b;

  .image {
    overflow: hidden;

    img {
      width: 100%;
      transform-origin: center;
      transform: scale(var(--img-scale));
      transition: transform 0.4s ease-in-out;
    }
  }
  .donate-desc {
    width: 90%;
    height: 100%;
    max-height: 250px;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    color: var(--title-color);

    h2 {
      font-family: "Courier New", Courier, monospace;
      color: var(--title-color);
      font-size: clamp(1.25rem, 2vw, 1.9rem);
      letter-spacing: 0.06em;
      transition: color 0.3s ease-out;
    }
    .donate-text {
      width: 100%;

      .text-left,
      .text-right {
        width: 100%;
        display: flex;
        a {
          color: var(--title-color);
          text-decoration: none;
        }
        a::after {
          position: absolute;
          inset-block: 0;
          inset-inline: 0;
          cursor: pointer;
          content: "";
        }
      }
      .text-left {
        justify-content: space-between;
        font-size: clamp(0.8rem, 1.5vw, 1.2rem);
        font-weight: 400;
      }
      .text-right {
        justify-content: flex-end;
        font-size: clamp(0.8rem, 1.5vw, 1.4rem);
        font-weight: 600;
      }
    }
    .progress-desc {
      width: 100%;

      .progress {
        background: rgba(255, 255, 255, 0.1);
        justify-content: flex-start;
        border-radius: 50px;
        align-items: center;
        position: relative;
        padding: 0 3px;
        display: flex;
        height: 20px;
        width: 100%;
      }

      .progress-value {
        width: 0; /* Initial width will be set dynamically */
        transition: width 0.8s ease-in-out; /* Smooth transition */
        box-shadow: 0 10px 40px -10px #fff;
        border-radius: 50px;
        background: var(--title-color);
        height: 20px;
      }

      .progress-text {
        width: 100%;

        .donated-text,
        .goal-text {
          color: var(--title-color);
        }

        .donated-text {
          float: left;
          font-size: clamp(0.8rem, 1.5vw, 1.2rem);
          font-weight: 600;
          font-family: "Times New Roman", Times, serif;
        }

        .goal-text {
          float: right;
          font-size: clamp(0.8rem, 1.5vw, 1.2rem);
          font-family: "Times New Roman", Times, serif;
        }
      }
    }
  }
}
