.card-img {
  position: relative;
  height: 100%;
  max-height: 1000px;
  background: #20a1b4;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  cursor: pointer;
  overflow: hidden;

  .content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;

    .imgBx {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;

      img {
        width: 400px;
        height: 400px;
        object-fit: cover;
      }
    }
    .contentBx h3 {
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      margin: 20px 0 10px;
      line-height: 1.1em;

      span {
        font-size: 12px;
        font-weight: 300;
        text-transform: initial;
      }
    }
  }

  &:hover .content {
    transform: translateY(-20px);
  }
  .sci {
    position: absolute;
    bottom: 50px;
    display: flex;

    li {
      list-style: none;
      margin: 0 10px;
      transform: translateY(40px);
      transition: 0.5s;
      opacity: 0;

      a {
        color: #fff;
        font-size: 20px;
        text-decoration: none;
      }
    }
  }
  &:hover .sci li {
    transform: translateY(0px);
    opacity: 1;
  }
}
@media screen and (min-width: 1500px) {
  .card-img .content .contentBx h3 {
    font-size: 24px;

    span {
      font-size: 16px;
    }
  }
}
