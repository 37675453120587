.slider-button {
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  position: absolute;

  button.prev,
  button.next {
    height: 90px;
    width: 100%;
    color: transparent;
    opacity: 0.6;
    text-indent: -99999px;
    cursor: pointer;
    border: none;
    z-index: 11;
  }

  button.prev:hover,
  button.next:hover {
    opacity: 1;
  }

  button.prev {
    background: url("https://lh4.googleusercontent.com/-JN1IZLtuToI/UUoZnMG3C_I/AAAAAAAAAE8/SEbJ9nqXGnY/s226/sprite.png")
      no-repeat -200px 25px;
    background-color: #000; /* Fallback for background */
  }

  button.next {
    background: url("https://lh4.googleusercontent.com/-JN1IZLtuToI/UUoZnMG3C_I/AAAAAAAAAE8/SEbJ9nqXGnY/s226/sprite.png")
      no-repeat -170px 25px;
    background-color: #000; /* Fallback for background */
  }
}
.slider-button.prev {
  left: 0;
}
.slider-button.next {
  right: 0;
}
