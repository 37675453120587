.pedestal {
  --bar-color: rgb(186, 93, 93);

  margin: 0 auto;
  height: 100%;
  width: 80%;
  position: relative;

  .bar-image {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    .bar {
      flex-grow: 1;
      background-color: var(--bar-color);
      width: 100%;
      max-width: 100px;
      border-radius: 5px 5px 0 0;
      position: relative;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .__1 {
    grid-row-start: 1;
    grid-row-end: 101;
  }
  .__2 {
    grid-row-start: 20;
    grid-row-end: 101;
  }
  .__3 {
    grid-row-start: 40;
    grid-row-end: 101;
  }
  .__4 {
    grid-row-start: 60;
    grid-row-end: 101;
  }
  .__5 {
    grid-row-start: 80;
    grid-row-end: 101;
  }
}
.grid-p {
  height: 80%;
  max-width: 870px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(100, 1fr);
  grid-column-gap: 5px;
}
.flex-l {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media Query For different screens */
@media (max-width: 749px) {
  .grid-p {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 750px) and (max-width: 1699px) {
  .grid-p {
    width: 100%;
  }
}
@media (min-width: 912px) and (max-width: 1024px) {
  .pedestal .bar {
    width: 102px;
  }
}
@media (min-width: 1025px) and (max-width: 1499px) {
  .pedestal .bar {
    width: 105px;
  }
}
@media (max-width: 1499px) {
  .pedestal .bar {
    margin: 0 auto;
  }
}
@media (min-width: 1500px) {
  .pedestal .bar-image .bar {
    max-width: 130px;
  }
}
