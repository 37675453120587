.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .mob-container {
    height: 650px;
    width: 360px;
    background-color: rgba(255, 255, 255, 0.13);
    backdrop-filter: blur(10px);
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    .grid-part {
      display: grid;

      .block {
        margin: 10px;
        display: inline-block;

        .front-hov {
          &:hover {
            cursor: pointer;
            transform: scale(1.015);
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.45);
          }
        }
      }
    }
    .form-section {
      height: 500px;
      width: 1440px;
      padding: 20px 0;
      position: relative;
      transition: all 0.5s ease-in-out;
      left: 0px;
      background: none;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 0;
      row-gap: 0;
      margin-top: 0;
      padding: 0;

      .grid-column {
        width: 360px;
      }
      .grid-paypal {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      .grid-metamask {
        grid-column-start: 4;
        grid-column-end: 5;
      }
    }
    .move-2 {
      left: -360px;
    }
    .move-3 {
      left: -724px;
    }
    .move-4 {
      left: -1103px;
    }
  }
}

@media screen and (max-width: 320px) {
  .flex-center .mob-container {
    height: 600px;
    width: 250px;

    .slider {
      height: 50px;
      width: 100px;
      left: 27px;
    }
    .moveslider {
      left: 127px;
    }
    .btn {
      height: 50px;
      width: 200px;
      margin: 20px auto;

      .privat,
      .mono {
        font-size: 19px;
      }
    }
    .form-section {
      height: 500px;
      width: 500px;
    }
    .form-section-move {
      left: -250px;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 344px) {
  .flex-center .mob-container {
    .form-section {
      width: 1256px;

      .grid-column {
        width: 344px;
      }
      .description {
        width: 342px;
      }
    }
    .move-2 {
      left: -344px;
    }
    .move-3 {
      left: -701px;
    }
    .move-4 {
      left: -1061px;
    }
    .grid-part .block {
      margin: 2px;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 399px) {
  .flex-center .mob-container {
    height: 600px;
  }
}
