.card-title {
  font-weight: 600;
  color: white;
  letter-spacing: -0.02em;
  line-height: 40px;
  font-style: normal;
  font-size: 28px;
  padding-bottom: 8px;
}
.card-description {
  font-weight: 600;
  line-height: 32px;
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 16px;
}

@media (min-width: 720px) and (max-height: 540px) {
  .card-title {
    line-height: 25px;
    font-size: 23px;
  }
  .card-description {
    line-height: 22px;
    font-size: 11px;
  }
}
