.username {
  color: #ffffff;
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
}

@media (max-width: 1499px) {
  .username {
    margin: auto auto;
  }
}
@media (min-width: 1500px) {
  .username {
    font-size: 26px;
  }
}
