.profile-pic {
  max-height: 100px;
  aspect-ratio: 1;
  border: clamp(3px, 0.4vw, 5px) solid white;
  border-radius: 100%;
  flex-shrink: 0;

  .profile-pic-image {
    background-color: rgba(255, 255, 255, 0.06);
    border-radius: 100%;
    margin: clamp(2px, 0.45vw, 7px);
    object-fit: cover;
    height: 90%;
    width: 90%;
  }
}
