.edit-item {
  margin: 0;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #ebe37d, #2f80ed);
  overflow: hidden;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #b9b9b9;
  background-color: #e3e3e3;

  .col-half,
  .edit-item-input {
    box-sizing: border-box;
  }

  .item-desc {
    display: flex;
    align-items: center;
    justify-content: center;

    .alert-message {
      position: absolute;
      top: 75px;
    }
    .item-card {
      overflow: hidden;
      text-align: center;
      max-width: 42em;
      padding: 1em 3em 2em 3em;
      margin: 0em auto;
      background-color: #fff;
      border-radius: 4.2px;
      box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.2);

      .row {
        zoom: 1;
        margin: 10px;

        .btn-container {
          text-align: center;
          display: flex;
        }
        .col-half {
          padding-right: 10px;
          float: left;
          width: 50%;
        }
        .col-half:last-of-type {
          padding-right: 0;
        }
      }
      .row:before,
      .row:after {
        content: "";
        display: table;
      }
      .row:after {
        clear: both;
      }
    }
  }
}
.edit-item > Header {
  flex-shrink: 0;
}
@media only screen and (max-width: 540px) {
  .col-half {
    width: 100%;
    padding-right: 0;
  }
}
