.back-black {
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
}
.back-violet {
  background: linear-gradient(71deg, #610081, #6200a3, #610081);
}
.title-container {
  position: relative;
  overflow: hidden;
  height: 150px;

  .title-row {
    top: -620px;
    left: -160px;
    font-size: 70px;
    transform: rotate(-30deg);

    .title-icons {
      color: #268500;
      padding: 0 8px;
    }
  }

  .title {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title-p {
      text-align: center;
      padding: 0 !important;
      font-size: 40px;
      color: #fff;
      font-weight: 600;
      line-height: 60px;
      overflow: hidden;
    }
  }
}

@media (max-width: 540px) {
  .title-container {
    height: 100px;

    .title .title-p {
      font-size: 27px;
      margin: 20px 0;
    }
  }
}
