.donated {
  color: white;
  background-color: #9a9a9a;
  width: 221px;
  position: absolute;
  top: -100px;
  left: 100px;
  text-align: left;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  border-radius: 10px;

  p {
    text-align: center;
  }
}
.underline {
  bottom: 0;
  height: 2px;
  width: 100%;

  &:before {
    position: absolute;
    left: 99px;
    top: -80px;
    content: "";
    height: 2px;
    width: 226px;
    background: #ffffff;
  }
}

@media (min-width: 1900px) {
  .donated {
    top: -120px;
    left: 108px;
  }
  .underline:before {
    top: -100px;
    left: 105px;
  }
}
