.text-container {
  gap: 10px;

  .text-logo-container {
    max-width: 1400px;
    margin: 0 auto;

    .logo-text {
      font-size: 70px;
      text-align: left;

      span:nth-child(1) {
        color: #008cff;
      }
      span:nth-child(2) {
        color: #fde624;
      }
    }
    .logo-slogan {
      font-size: 40px;
      text-align: right;
    }
  }
  .about-container {
    max-width: 600px;
    width: 50%;
  }
  .about-container.right {
    text-align: right;
  }
  .about-container.center {
    text-align: center;
  }
  .text-title {
    color: #008cff;
    font-size: 40px;
    margin-bottom: 10px;
  }
  .text {
    font-size: 20px;

    span a {
      color: #fde624;
      text-decoration: none;
    }
    span a:hover {
      text-decoration: underline;
    }
  }
  .text.list {
    margin-bottom: 10px;
  }
}
.text-container.text-left {
  text-align: left;
}
.text-container.text-right {
  text-align: right;
}
.text-container.text-center {
  text-align: center;
}
.mg-20 {
  margin-bottom: 20px;
}
.mg-35 {
  margin-bottom: 35px;
}
.mg-70 {
  margin-bottom: 70px;
}
.mg-100 {
  margin-bottom: 100px;
}
.mg-150 {
  margin-bottom: 150px;
}
.text-columns {
  display: flex;
  justify-content: space-between;
}
.animation {
  animation: chainReaction 3s;
  opacity: 0;
  animation-fill-mode: forwards;
}
.animation:nth-child(1) {
  animation-delay: 0.3s;
}
.animation:nth-child(2) {
  animation-delay: 1s;
}
.animation:nth-child(3) {
  animation-delay: 1.7s;
}
.animation:nth-child(4) {
  animation-delay: 2.4s;
}
.animation:nth-child(5) {
  animation-delay: 3s;
}

@keyframes chainReaction {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Media Query For different screens */
@media (max-width: 750px) {
  .text-container {
    justify-content: center;

    .text-logo-container {
      .logo-text,
      .logo-slogan {
        text-align: center;
      }
    }
    .about-container {
      width: 100%;
      margin: 0 auto;
      max-width: 670px;
    }
    .text-title.mobile {
      margin: 0 auto 10px auto;
    }
    .text.mobile {
      margin: 0 auto 10px auto;
    }
  }
}
@media (max-width: 344px) {
  .text-container .text.mobile {
    width: 240px;
  }
}
@media (min-width: 345px) and (max-width: 499px) {
  .text-container .text.mobile {
    width: 285px;
  }
}
@media (max-width: 499px) {
  .text-container {
    .text-logo-container {
      .logo-text {
        font-size: 34px;
      }
      .logo-slogan {
        font-size: 15px;
      }
    }
    .text-title {
      font-size: 20px;
    }
    .text-title.mobile {
      width: 300px;
    }
    .text {
      font-size: 12px;
    }
  }
}
@media (min-width: 500px) and (max-width: 944px) {
  .text-container {
    .text-logo-container .logo-text {
      font-size: 40px;
    }
    .text-logo-container .logo-slogan {
      font-size: 25px;
    }
    .text-title {
      font-size: 25px;
    }
    .text {
      font-size: 12px;
    }
    .text.mobile {
      width: 400px;
    }
  }
}
@media (min-width: 945px) and (max-width: 1023px) {
  .text-container {
    .text-logo-container .logo-text {
      font-size: 45px;
    }
    .text-logo-container .logo-slogan {
      font-size: 27px;
    }
    .text-title {
      font-size: 27px;
    }
    .text {
      font-size: 14px;
    }
  }
}
@media (min-width: 1024px) and (max-width: 1179px) {
  .text-container {
    .text-logo-container .logo-text {
      font-size: 60px;
    }
    .text-logo-container .logo-slogan {
      font-size: 35px;
    }
    .text-title {
      font-size: 27px;
    }
    .text {
      font-size: 14px;
    }
  }
}
@media (min-width: 1180px) and (max-width: 1299px) {
  .text-container {
    .text-logo-container .logo-text {
      font-size: 60px;
    }
    .text-logo-container .logo-slogan {
      font-size: 35px;
    }
    .text-title {
      font-size: 25px;
    }
    .text {
      font-size: 15px;
    }
  }
}
@media (min-width: 1300px) and (max-width: 1400px) {
  .text-container {
    .text-logo-container .logo-text {
      font-size: 65px;
    }
    .text-logo-container .logo-slogan {
      font-size: 40px;
    }
    .text-title {
      font-size: 30px;
    }
    .text {
      font-size: 20px;
    }
  }
}
@media (min-width: 900px) and (orientation: portrait) {
  .text-container {
    .text-logo-container .logo-text {
      font-size: 65px;
    }
    .text-logo-container .logo-slogan {
      font-size: 40px;
    }
    .text-title {
      font-size: 30px;
    }
    .text {
      font-size: 20px;
    }
  }
}
