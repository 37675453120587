.donate-change {
  background: #eeedef;
  display: flex;
  flex-direction: column;
  height: 88px;
  justify-content: center;
  position: relative;
  width: 280px;
  border-radius: 30px;

  .bar {
    display: flex;
    position: relative;
    width: 100%;
    height: 40px;
    justify-content: space-between;

    .option {
      cursor: pointer;
      flex: 1;
      line-height: 40px;
      text-align: center;
      color: black; /* Default inactive text color */
      transition: color 200ms ease-in-out;
    }
    .option.active {
      color: white; /* White color for active option */
      z-index: 1; /* Ensure the text stays above the ellipse */
    }
  }
  .ellipse {
    background: #6000ea;
    border-radius: 20px / 50%; /* Creates straight top and bottom, rounded sides */
    height: 40px;
    position: absolute;
    width: 90px;
    transition: transform 200ms ease-in-out, background-color 200ms ease-in-out;
    z-index: 0;
  }
  .pos1 {
    transform: translateX(27%); /* Start at first option */
  }

  .pos2 {
    transform: translateX(182%); /* Slide to second option */
  }
}

@media (min-width: 720px) and (max-height: 540px) {
  .donate-change {
    height: 45px;
    width: 235px;

    .ellipse {
      height: 33px;
      width: 90px;
    }
    .pos1 {
      transform: translateX(15%);
    }
    .pos2 {
      transform: translateX(145%);
    }
  }
}
@media (min-width: 1024px) and (max-height: 600px) {
  .donate-change {
    height: 40px;
    width: 246px;

    .ellipse {
      height: 33px;
      width: 90px;
    }
    .pos1 {
      transform: translateX(19%);
    }
    .pos2 {
      transform: translateX(154%);
    }
  }
}
